import { useState,useEffect } from "react"
import PedidoServiceInstance from "../../../Servicios/PedidoService";

function VerPedidos() {
    const [pedidos, setPedidos] = useState([]);

    useEffect(() => {
        const traerOrden = async () => {
            try {
                const data = await PedidoServiceInstance.getGaleria();
                setPedidos(data);
            } catch (error) {
                console.error("Error al traer los datos:", error);
            }
        };

        traerOrden();
    }, []);

    return (
        <div className="pedido-contenedor">
        
            {pedidos.slice().reverse().map((orden) => (
                <div className="pedido-elemento" key={orden.id}>
                    <h2>Pedido: {orden.id}</h2>
                    <p>Costo Total: ${orden.orderCost}</p>
                    <p>Estado: {orden.status}</p>
                    <button>Finalizado</button>
                    <p>Cliente : {orden.user.username}</p>
                    <p>Telefono : {orden.user.phoneNumber}</p>
                    {orden.salads.map((ensalada) => (
                        <div className="pedido-ensalada" key={ensalada.id}>
                            <h4>Tipo: {ensalada.saladType.value}</h4>
                            {ensalada.ingredients.map((ingredient) => (
                                <div className="pedido-ingrediente" key={ingredient.id}>
                                    <h5>Ingrediente: {ingredient.value}</h5>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default VerPedidos