import { API_URLS } from '../constantes/configs';

class GaleriaService {
    async getGaleria() {
        try {
            const response = await fetch(API_URLS.ingredients);
            if (!response.ok) {
                throw new Error("Error al cargar toda la galeria");
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    async postIngrediente(ingrediente) {
        try {
            const response = await fetch(API_URLS.ingredient, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(ingrediente),
            });
            if (!response.ok) {
                throw new Error("Error al crear el ingrediente");
            }
            const dato = await response.json();
            return dato;
        } catch (error) {
            console.error(error);
        }
    }

    async eliminar(id) {
        try {
            const response = await fetch(API_URLS.removeIngredient(id), {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
            });
            if (!response.ok) {
                throw new Error("No se pudo eliminar el ingrediente");
            }
            const dato = await response.json();
            return dato;
        } catch (error) {
            console.error(error);
        }
    }
}

const GaleriaServiceInstance = new GaleriaService();
export default GaleriaServiceInstance;