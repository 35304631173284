import React from "react";
import { Link } from "react-router-dom";
import "../../../Estilos/Menu.css"

function MenuAdmin() {

  const salir =()=>{
    localStorage.removeItem('userRole');
    window.location.href = '/login'
  }

  return (
    <nav>
      <div className="menu">
        <ul className="menu-lista">
          <li><Link to="/crearingrediente">Crear Ingrediente</Link></li>
          <li><Link to="/verpedidos">Ver Pedidos</Link></li>
          <button onClick={salir}>salir</button>
        </ul>
      </div>
    </nav>
  );
}

export default MenuAdmin;