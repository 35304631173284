import { useEffect, useState,useContext } from "react";
import GaleriaServiceInstance from "../../../Servicios/GaleriaService";
import "../../../Estilos/EstiloCrearPedido.css"
import PedidoServiceInstance from "../../../Servicios/PedidoService";
import { LoginContext } from "../../../Contexto/LoginContext";
import EnsaladaServiceInstance from "../../../Servicios/EnsaladaService";


function CrearPedido(){

    const [galeria, setGaleria] = useState([]);
    const [pedido,setPedido] = useState([]);
    const [ensalada,setEnsalada] = useState([])


    const { user } = useContext(LoginContext);

    useEffect(() => {
        const fetchGaleria = async () => {
            const dato = await GaleriaServiceInstance.getGaleria();
            setGaleria(dato);
        };
        fetchGaleria();
    }, []);


    const agregar = (ingrediente) =>{
        if (!pedido.some(ingred => ingred.id === ingrediente.id)){
            setPedido([...pedido, ingrediente]);
        console.log("se agrego "+ingrediente.value)}
    }


    const eliminar = (ingrediente) => {
        setPedido(pedido.filter(item => item.id !== ingrediente.id));
    };


    
    const enviarPedido = () => {
        const ids = pedido
        .filter(item => item !== undefined) 
        .map(item => ({id:item.id})); 
       
        const nuevaEnsalada = [...ensalada, ids];
        
       
        setEnsalada(nuevaEnsalada);
        
        // Limpia el pedido actual
        setPedido([]);
        
        
        console.log("Nueva ensalada agregada:", nuevaEnsalada);
    }

    


    const crearPedido = async () => {
            // Crear ensaladas y obtener los IDs
            const ensaladaIds = await Promise.all(
                ensalada.map(async (ensa) => {
                    try {
                        const ensaladas = {
                            saladType: { "id": 1 },
                            ingredients: ensa
                        };
                        const response = await EnsaladaServiceInstance.crearEnsalada(ensaladas);
                        return {"id":response.id}; // Asume que response tiene un campo 'id'
                    } catch (error) {
                        console.error('Error al crear la ensalada:', error);
                        throw error; // Propaga el error para manejarlo más adelante
                    }
                })
            );
    
            console.log('IDs de ensaladas creadas:', ensaladaIds);

    
           
           const orden = {
                user: {"id":user.id},
                salads: ensaladaIds,
                orderCost:1500,
                status:"En Preparacion"
            };
    
            await PedidoServiceInstance.createOrder(orden);
            console.log('Orden creada con éxito');
       
    };



  

    return(
        <div className="container">
            <div className="left-column">
                <div>
                    <h1>Hoja</h1>
                    {galeria.map((ingrediente) => {
                        if (ingrediente.ingredientsType.id === 1) {
                            return (
                                <div key={ingrediente.id}>
                                    <button className="button-ingredient" onClick={()=>agregar(ingrediente)}>{ingrediente.value}</button>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
                <br/>
                <div>
                    <h1>Proteinas</h1>
                    {galeria.map((ingrediente) => {
                        if (ingrediente.ingredientsType.id === 2) {
                            return (
                                <div key={ingrediente.id}>
                                    <button className="button-ingredient" onClick={()=>agregar(ingrediente)}>{ingrediente.value}</button>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
                <br/>
                <div>
                    <h1>Tuberculo</h1>
                    {galeria.map((ingrediente) => {
                        if (ingrediente.ingredientsType.id === 3) {
                            return (
                                <div key={ingrediente.id}>
                                    <button className="button-ingredient" onClick={()=>agregar(ingrediente)}>{ingrediente.value}</button>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
            </div>
            <div className="right-column">
                <div className="cajaEnsaladera">
                    {pedido.map((ingrediente) =>{
                        return(
                        <div key={ingrediente.id}>
                            <button onClick={()=>eliminar(ingrediente)}>{ingrediente.value}</button>
                        </div>)
                    })}
                </div>
                <button onClick={()=>enviarPedido()}>Pedir</button>
            </div>
            <button onClick={()=>crearPedido()}>Finalizar Pedido</button>
        </div>
    )
}

export default CrearPedido;