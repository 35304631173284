import { BrowserRouter , Route, Routes} from "react-router-dom";
import CrearIngrediente from "./Users/Admin/CrearIngrediente";
import VerPedidos from "./Users/Admin/VerPedido";
import Registro from "./Users/Registro";
import Login from "./Users/Login";
import MenuAdmin from "./Users/Admin/MenuAdmin";
import MenuLogin from "./Users/MenuLogin";
import MenuCliente from "./Users/Cliente/MenuCliente";
import CrearPedido from "./Users/Cliente/CrearPedido"
import VerPedidosCliente from "./Users/Cliente/VerPedidosCliente"
import { useEffect, useState } from "react";
import { LoginProvider } from '../Contexto/LoginContext';

function App() {
  
  const [role,setRole]= useState(false);

  useEffect (()=>{
    const storageRole = localStorage.getItem('userRole');
    setRole(storageRole);
  },[])

  return (
  <LoginProvider>  
    <div>
      { role === "admin" ? (
          <div>
          <BrowserRouter>
              <MenuAdmin />
              <Routes>
                <Route path="/crearingrediente" element={<CrearIngrediente/>}></Route>
                <Route path="/verpedidos" element={<VerPedidos/>}></Route>
              </Routes>
            </BrowserRouter>
          </div>) : role==="cliente" ?(<div>
            <BrowserRouter>
              <MenuCliente/>
              <Routes>
                <Route path="/CrearPedido" element={<CrearPedido/>}></Route>
                <Route path="/VerPedidos" element={<VerPedidosCliente/>}></Route>
              </Routes>
            </BrowserRouter>
          </div>) : (
          <div>
            <BrowserRouter>
              <MenuLogin/>
              <Routes>
                <Route path="login" element={<Login/>}></Route>
                <Route path="registro" element={<Registro/>}></Route>
              </Routes>
            </BrowserRouter>
          </div>)}    
  </div>
</LoginProvider>  
)}
      
    
export default App;
