// src/contexto/LoginContext.js
import React, { createContext, useState, useEffect } from 'react';

// Crear el contexto
export const LoginContext = createContext();

// Proveedor del contexto
export const LoginProvider = ({ children }) => {
    const [user, setUser] = useState(null); // Estado inicial del usuario

    // Función para manejar el login
    const login = (userData) => {
        console.log("userData:", userData);  // Para verificar qué se está guardando
        if (userData) {  // Verificar que no sea null o undefined
            setUser(userData);
            localStorage.setItem('user', JSON.stringify(userData));
        } else {
            console.error("userData is invalid:", userData);
        }
    };

    // Función para manejar el logout
    const logout = () => {
        setUser(null);
        localStorage.removeItem('user');
    };

    // Cargar el usuario desde localStorage si existe (para mantener la sesión tras recargar la página)
    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            try {
                setUser(JSON.parse(storedUser));
            } catch (error) {
                console.error("Error parsing JSON from localStorage:", error);
                localStorage.removeItem('user'); // Eliminar el valor inválido
            }
        }
    }, []);

    return (
        <LoginContext.Provider value={{ user, login, logout }}>
            {children}
        </LoginContext.Provider>
    );
};