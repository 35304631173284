const BASE_URL = "http://localhost:8080";

export const API_URLS = {
    createOrder: `${BASE_URL}/order`,
    orders: `${BASE_URL}/orders`,
    users: `${BASE_URL}/users`,
    user: `${BASE_URL}/user`,
    login: `${BASE_URL}/login`,
    ingredients: `${BASE_URL}/ingredients`,
    ingredient: `${BASE_URL}/ingredient`,
    ingredientsType: `${BASE_URL}/ingredientstype`,
    salad: `${BASE_URL}/salad`,
    removeIngredient: (id) => `${BASE_URL}/ingredient/${id}`,
    orderId: (id) => `${BASE_URL}/order/${id}`,
};