import React, { useEffect } from "react";
import { useState } from "react";
import '../../../Estilos/galeria.css';
import GaleriaServiceInstance from "../../../Servicios/GaleriaService";
import TipoIngredienteService from "../../../Servicios/IngredienteServicio";



function CrearIngrediente(){
  const [tipoDeIngrediente,setTipoDeIngrediente] = useState("");
  const [ingrediente,setIngrediente] = useState("");
  const [estado,setEstado] = useState("");
  const [precio,setPrecio] = useState("")
  const [tipoIngrediente,setTipoIngrediente] = useState("")
  const [galeria,setGaleria] = useState([]);


  useEffect(() => {
    const fetchGaleria = async () => {
      const data = await GaleriaServiceInstance.getGaleria();
      setGaleria(data || []);
    };
    fetchGaleria();
  }, []);

  const Agregar = async (item) => {
    await GaleriaServiceInstance.postIngrediente(item);
    const updatedGaleria = await GaleriaServiceInstance.getGaleria();
    setGaleria(updatedGaleria || []);
  };


  const eliminar = async (id) =>{
    await GaleriaServiceInstance.eliminar(id);
    const updatedGaleria = await GaleriaServiceInstance.getGaleria();
    setGaleria(updatedGaleria || [])
  }


  const AgregarIngrediente= async(typIng)=>{
    await TipoIngredienteService.postTipoIngrediente(typIng);
  }
  

  return (    
    <div className="App">
      <h1>Crear tipo de ingrediente</h1>
      <input placeholder="Tipo de ingrediente" value={tipoDeIngrediente} onChange={e => setTipoDeIngrediente(e.target.value)} />
      <br />
      <button onClick={()=>AgregarIngrediente({
                                  value:tipoDeIngrediente
                                })}>Agregar</button>



      <h1>Crear Ingredientes</h1>
      <input placeholder="Tipo de Ingrediente" value={tipoIngrediente} onChange={e => setTipoIngrediente(e.target.value)} />
      <br />
      <input placeholder="Nombre del ingrediente" value={ingrediente} onChange={e => setIngrediente(e.target.value)} />
      <br />
      <input placeholder="estado" value={estado} onChange={e => setEstado(e.target.value)} />
      <br />
      <input placeholder="Precio" value={precio} onChange={e => setPrecio(e.target.value)} />
      <br />
      <button onClick={()=>Agregar({
                                  ingredientsType:{id:tipoIngrediente},
                                  value:ingrediente,
                                  status:estado,
                                  price:precio
                                })}>Agregar</button>



      <br/>
      <br/>
        <div className="form-container"> 
        {galeria.map((item)=>(
          <div key={item.id} className="ingredientes">
                <p>Tipo de Ingrediente: {item.ingredientsType.value}</p>
                <p>Nombre: {item.value}</p>
                <p>Estado: {item.status}</p>
                <p>Precio: {item.price}</p>
                <button onClick={()=>eliminar(item.id)}>Eliminar</button>
          </div>
        ))} 
        </div>                         
                                 
      </div>
  );
}

export default CrearIngrediente