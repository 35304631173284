import { API_URLS } from '../constantes/configs';

class UsuariosService {
    async getUser() {
        try {
            const response = await fetch(API_URLS.users);
            if (!response.ok) {
                throw new Error("Error para obtener los usuarios");
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    async crearUsuario(user) {
        try {
            const response = await fetch(API_URLS.user, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(user),
            });
            if (!response.ok) {
                throw new Error("Error para crear un usuario");
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    async login(user) {
        try {
            const response = await fetch(API_URLS.login, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(user),
            });
            if (!response.ok) {
                throw new Error("Error para encontrar usuario");
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
        }
    }
}

const UsuariosServiceInstance = new UsuariosService();
export default UsuariosServiceInstance;


