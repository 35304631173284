import { Link } from "react-router-dom"

function MenuCliente(){

    const salir = () =>{
        localStorage.removeItem('userRole');
        window.location.href = '/login'
    }

    return(
        <nav>
            <div className="menu">
                <ul className="menu-lista">
                    <li><Link to="/CrearPedido">Crear Pedido</Link></li>
                    <li><Link to="/VerPedidos">Ver Pedido</Link></li>
                    <button onClick={salir}>Salir</button>
                </ul>
            </div>
            
        </nav>
    )
}

export default MenuCliente