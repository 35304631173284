import React, { useState,useContext } from "react";
import UsuariosServiceInstance from "../../Servicios/UsuariosService";
import { LoginContext } from "../../Contexto/LoginContext";


function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginMessage, setLoginMessage] = useState("");
  
    const { login } = useContext(LoginContext);

    const logins = async (usuarioLogin) => {
        try {
            const response = await UsuariosServiceInstance.login(usuarioLogin);
            login(response);
            console.log(response)
            const role = response.role
            
            if (role === "admin") {
                localStorage.setItem('userRole', role);
                window.location.href = '/crearingrediente' 
            }if(role==="cliente"){
                 localStorage.setItem('userRole', role);
                window.location.href = '/CrearPedido' 
        }} catch (error) {
            console.error("Error durante el inicio de sesión:", error);
            setLoginMessage("Ocurrió un error, por favor intenta nuevamente");
        }
    };

    return (
        <div>
            <h1>Login</h1>
            <input
                placeholder="username"
                value={username}
                onChange={e => setUsername(e.target.value)}
            />
            <br />
            <input
                placeholder="password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
            />
            <br />
            <button onClick={() => logins({ username, password })}>Ingresar</button>
            <p>{loginMessage}</p>
        </div>
    );
}

export default Login;