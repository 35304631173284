import { API_URLS } from '../constantes/configs';

class IngredienteServicio{

    async postTipoIngrediente(tipoIngrediente) {
        try {
            const response = await fetch(API_URLS.ingredientsType, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(tipoIngrediente)
            });

            if (!response.ok) {
                throw new Error("La orden no pudo ser cargada");
            }

            // Opcional: Puedes devolver algo si necesitas manejar la respuesta
            const result = await response.json();
            return result;
        } catch (error) {
            console.error("Error al crear la orden:", error);
            throw error; // Propaga el error para manejarlo más arriba si es necesario
        }
    }

}


const TipoIngredienteService = new IngredienteServicio()
export default TipoIngredienteService