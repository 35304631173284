import { Link } from "react-router-dom"

function MenuLogin (){
    return(
        <nav>
            <div className="menu">
                <ul className="menu-lista">
                    <li><Link to="login" >Login</Link></li>
                    <li><Link to="registro">Registro</Link></li>
                </ul>
            </div>
        </nav>
    )
}


export default MenuLogin