import { API_URLS } from '../constantes/configs';

class EnsaladaService{

    async crearEnsalada(ensalada){
        try{
            const response = await fetch(API_URLS.salad,
            {
                method:"POST",
                headers:{"Content-Type":"application/json"},
                body:JSON.stringify(ensalada)
            }
        )
        if(!response.ok){
            throw new Error("no se puedo cargar la ensalada")
        }
        const data = response.json()
        return data
        
    }
        catch(error){
            console.error(error)
        }

        
    }


}






const EnsaladaServiceInstance =new EnsaladaService();

export default EnsaladaServiceInstance;