import React, { useState } from "react";
import UsuariosServiceInstance from "../../Servicios/UsuariosService";

function Registro(){
    const [role,setRole]=useState("");
    const [usuario,setUsuario] = useState("");
    const [password,setPassword] = useState("");
    const [email,setEmail] = useState("");
    const [numero,setNumero] = useState("");


    const enviar =(user)=>{
        const response = UsuariosServiceInstance.crearUsuario(user)
        if(response.ok){
            console("se cargo correctamente")
        }
    }


    return(
        <div>
            <h1>Registro</h1>
            <input placeholder="role" value={role} onChange={e=>setRole(e.target.value)}/>
            <br/>
            <input placeholder="usuario" value={usuario} onChange={e=>setUsuario(e.target.value)} />
            <br/>
            <input placeholder="password" value={password} onChange={e=>setPassword(e.target.value)} />
            <br/>
            <input placeholder="email" value={email} onChange={e=>setEmail(e.target.value)} />
            <br/>
            <input placeholder="numero de telefono" value={numero} onChange={e=>setNumero(e.target.value)} />
            <br/>
            <button onClick={()=>enviar({
                                        role:role,
                                        username:usuario,
                                        password:password,
                                        email:email,
                                        phoneNumber:numero
                                    })}>Aceptar</button>
        </div>
    );
}

export default Registro;