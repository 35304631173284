import { API_URLS } from '../constantes/configs';
import GaleriaServiceInstance from "./GaleriaService";

class PedidoService {
    async createOrder(orden) {
        try {
            const response = await fetch(API_URLS.order, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(orden)
            });

            if (!response.ok) {
                throw new Error("La orden no pudo ser cargada");
            }

            // Opcional: Puedes devolver algo si necesitas manejar la respuesta
            const result = await response.json();
            return result;
        } catch (error) {
            console.error("Error al crear la orden:", error);
            throw error; // Propaga el error para manejarlo más arriba si es necesario
        }
    }

    async getGaleria() {
        try {
            const response = await fetch(API_URLS.orders);

            if (!response.ok) {
                throw new Error("No se pudieron traer las órdenes");
            }

            const dato = await response.json(); // Debe ser await
            return dato;
        } catch (error) {
            console.error("Error al obtener la galería:", error);
            throw error; // Propaga el error para manejarlo más arriba si es necesario
        }
    }

    async getGaleriaId(id) {
        try {
            const response = await fetch(API_URLS.orderId(id));

            if (!response.ok) {
                throw new Error("No se pudieron traer las órdenes");
            }

            const dato = await response.json(); // Debe ser await
            return dato;
        } catch (error) {
            console.error("Error al obtener la galería:", error);
            throw error; // Propaga el error para manejarlo más arriba si es necesario
        }
    }


}

const PedidoServiceInstance = new PedidoService();
export default PedidoServiceInstance;